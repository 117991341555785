import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { expandChat } from '@hotelplan/components.common.chat';
import { Schedule } from '@hotelplan/components.common.help-overlay';
import { Icon } from '@hotelplan/components.common.icon';
import { InfoCard } from '@hotelplan/components.common.info-card';
import { Link } from '@hotelplan/components.common.link';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import {
  AuthChannelType,
  DeviceType,
  PageLanguage,
} from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useRequestContext } from '@hotelplan/libs.context.req-ctx';
import { getRouterLink } from '@hotelplan/libs.router-link-utils';
import { ClickEventType, trackClicks } from '@hotelplan/libs.tracking';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import Chat from 'components/domain/chat-container/ChatContainer';
import { Container } from 'components/domain/container';
import { useHelpOverlayToggleStateContext } from 'components/domain/site-tool-buttons';
import { IInactivityTimerProps } from 'components/domain/timer/useInactivityTimer';
import { useGetPersonalProfileQuery } from 'graphql/account/GetPersonalProfile.generated';
import { useGetAgencyContactQuery } from 'graphql/agency/GetAgencyContact.generated';
import { useGetHelpOverlayQuery } from 'graphql/layout/GetHelpOverlay.generated';
import HelpOverlaySkeleton from './HelpOverlayItem.skeleton';
import { useHelpOverlayInactivityTimer } from './useHelpOverlayInactivityTimer';

export const HelpOverlayInactivityTimer: React.FC<IInactivityTimerProps> = props => {
  useHelpOverlayInactivityTimer(props);
  return null;
};

const HelpOverlayWrapper = styled(Container).attrs({
  'data-id': `help-overlay`,
})(({ theme }) => theme.helpOverlay.container);

const HelpOverlayIcon = styled.div(
  ({ theme, theme: { media, space, colors } }) => ({
    marginTop: space[1],
    textAlign: 'center',
    alignContent: 'flex-end',
    fontSize: '0px',
    [media.tablet]: {
      marginTop: space[0],
    },
    '> .-icon-link': theme.link.iconLink,
    '.-icon-border': {
      transition: 'all 0.2s ease-in',
      color: 'white',
      backgroundColor: 'white',
      borderRadius: '50%',
      fontSize: '0px',
      display: 'flex',
      padding: '0px',
      justifyContent: 'center',
      alignItems: 'center',
      letterSpacing: '0',
      width: '48px',
      height: '48px',
      border: '2px solid',
      borderColor: colors.primary,
      '&:hover, &:focus, &:focus-visible': {
        backgroundColor: colors.lightMint,
        border: '2px solid',
        borderColor: colors.interactionPrimary,
        textDecoration: 'underline',
        outline: 'none',
      },
    },
    '.-icon-chevron-right': {
      width: '24px',
      height: '24px',
      color: colors.primary,
    },
  })
);

const HelpOverlayTitle = styled.div(({ theme: { FONT_SIZE } }) => FONT_SIZE.M);

export const HelpOverlayContent = styled.div(
  ({ theme: { FONT_SIZE, colors } }) =>
    sx2CssThemeFn({
      p: '0',
      '.-image-text': {
        ...FONT_SIZE.LARGE,
        letterSpacing: '0.7px',
        textAlign: 'left',
        'a[href^="tel:"]': {
          color: colors.mainRed,
          textDecoration: 'none',
          textDecorationColor: colors.mainRed,
          '&:hover,&:focus': {
            color: colors.mainRed,
            textDecoration: 'underline',
          },
        },
        p: {
          mb: 2,
          '&:last-of-type': {
            mb: '0',
          },
        },
      },
      '> p.-image-text-secondary': {
        color: 'secondary',
        ...FONT_SIZE.SMALL,
      },
      '> p.-help-overlay-phone-title': {
        ...FONT_SIZE.LARGE,
        letterSpacing: '0.7px',
      },
      '> p.-image-text-phone-link': {
        ...FONT_SIZE.LARGE,
        color: 'mainRed',
      },
    })
);

const HelpOverlayClose = styled(BsButton).attrs({
  variant: E_BUTTON_TYPE.LINK_BUTTON,
})(({ theme }) => theme.helpOverlay.close);

export const InfoCardWrapper = styled(InfoCard)(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    '.help-overlay-content-icon': {
      display: 'flex',
      gap: 4,
    },
    '.info-card-icon': {
      display: 'none',
    },
    '.help-overlay-contact-top': {
      display: 'flex',
      flexDirection: 'column',
      gap: 3,
      borderBottom: '1px solid',
      borderBottomColor: 'linkUnderlineColor',
      pb: 4,
    },
    '.help-overlay-contact-bottom': {
      '.additional-info': {
        variant: 'text.richText',
        ...FONT_SIZE.SMALL,
        p: { mb: 0 },
        'p:nth-of-type(2)': {
          mt: 3,
          color: 'darkGray',
        },
      },
    },
    '#olark-box-container': {
      display: 'none',
    },
  })
);

export const AgencyPhone = styled(Link)({
  '&:hover, &:focus': {
    textDecoration: 'underline',
    color: 'inherit',
  },
});

export enum HelpOverlayEvent {
  Open = 'help-ov-open',
  Close = 'help-ov-close',
}

export enum HelpOverlayBoxes {
  BoxContact = 'HelpOverlayBoxContact',
  BoxChat = 'HelpOverlayBoxChat',
  BoxGeneral = 'HelpOverlayBox',
}

export function openHelpOverlay() {
  window.dispatchEvent(new Event(HelpOverlayEvent.Open));
}

export function onOpenOverlayEvent(callback: () => void): () => void {
  window.addEventListener(HelpOverlayEvent.Open, callback);
  return () => {
    window.removeEventListener(HelpOverlayEvent.Open, callback);
  };
}

export const HelpOverlay: React.FC<{
  onClose?(): void;
}> = ({ onClose }) => {
  const context = useRequestContext<DeviceType, PageLanguage>();
  const { channelType } = useAuthentication();
  const withAccountAgency = channelType === AuthChannelType.B2C;
  const isB2b = channelType === AuthChannelType.B2B;
  const { disable: closeHelpOverlay } = useHelpOverlayToggleStateContext();

  const { data: overlayData } = useGetHelpOverlayQuery({
    ssr: false,
  });

  const { data: account, loading: accountLoading } = useGetPersonalProfileQuery(
    {
      variables: { context },
      skip: !withAccountAgency,
    }
  );

  const { agency } = useRequestContext();

  const {
    data: agencyContactData,
    loading: agencyContactLoading,
  } = useGetAgencyContactQuery({
    variables: { id: agency || account?.account?.personalData?.agency || '' },
    skip: withAccountAgency && accountLoading,
  });

  const { mobile } = useDeviceType();

  const [t] = useTranslation('common');

  if (
    !overlayData?.helpOverlayData.helpOverlayBoxes ||
    accountLoading ||
    agencyContactLoading
  ) {
    return <HelpOverlaySkeleton />;
  }

  return (
    <HelpOverlayWrapper>
      {(overlayData?.helpOverlayData.helpOverlayBoxes || [])
        .map((box, i, boxes) => {
          switch (box?.__typename) {
            case HelpOverlayBoxes.BoxChat:
              return (
                <React.Fragment key={i}>
                  <InfoCardWrapper
                    icon={box.icon}
                    boxesCount={boxes.length}
                    key={i}
                    link={box.targetUrl}
                    onClick={e => {
                      e.preventDefault();
                      expandChat();
                      trackClicks({
                        type: ClickEventType.CHAT,
                        payload: { source: 'help' },
                      });
                    }}
                    className="info-card"
                    wrapperClassName={`overlay-box box-chat ${
                      isB2b ? 'b2b' : 'b2c'
                    }`}
                  >
                    <HelpOverlayTitle>
                      <h4>{box.mainTitle}</h4>
                    </HelpOverlayTitle>
                    <div className="help-overlay-content-icon">
                      <HelpOverlayContent>
                        {!mobile && box.mainText && (
                          <div
                            className="-image-text"
                            dangerouslySetInnerHTML={{ __html: box.mainText }}
                          />
                        )}
                      </HelpOverlayContent>
                      <HelpOverlayIcon className="help-overlay-icon">
                        <div className="-icon-link">
                          <div className="-icon-border">
                            <Icon
                              name="chevron-right"
                              className="-icon-chevron-right"
                            />
                          </div>
                        </div>
                      </HelpOverlayIcon>
                    </div>
                    <Chat />
                  </InfoCardWrapper>
                </React.Fragment>
              );
            case HelpOverlayBoxes.BoxContact:
              return (
                agencyContactData?.agencyContact && (
                  <InfoCardWrapper
                    boxesCount={boxes.length}
                    key={i}
                    icon={box.icon}
                    onClick={() => {
                      trackClicks({
                        type: ClickEventType.CALL,
                        payload: { source: 'help' },
                      });
                    }}
                    link={box.targetUrl}
                    className="info-card"
                    wrapperClassName={`overlay-box box-call ${
                      isB2b ? 'b2b' : 'b2c'
                    }`}
                  >
                    <div className="help-overlay-contact-top">
                      <HelpOverlayTitle>
                        <h4>{t('help.callUs')}</h4>
                      </HelpOverlayTitle>
                      <div className="help-overlay-content-icon">
                        <HelpOverlayContent>
                          {mobile && (
                            <p className="-image-text-secondary">
                              {agencyContactData.agencyContact.phone.title +
                                ':'}
                            </p>
                          )}
                          {!mobile &&
                            agencyContactData.agencyContact.phone.title && (
                              <p
                                className="-help-overlay-phone-title"
                                data-id="help-overlay-phone-title"
                              >
                                {agencyContactData.agencyContact.phone.title +
                                  ':'}
                              </p>
                            )}
                          {agencyContactData.agencyContact.phone.link
                            .caption && (
                            <p
                              className="-image-text-phone-link"
                              data-id="help-overlay-phone-link"
                            >
                              <AgencyPhone
                                href={`tel:${agencyContactData.agencyContact.phone.link.caption}`}
                              >
                                {
                                  agencyContactData.agencyContact.phone.link
                                    .caption
                                }
                              </AgencyPhone>
                            </p>
                          )}
                        </HelpOverlayContent>
                      </div>
                      <Schedule
                        openingHours={
                          agencyContactData.agencyContact.openingHours
                        }
                        timezone={agencyContactData.agencyContact.timezone}
                        showStatusIcon={false}
                        className={'opening-hours'}
                      />
                    </div>
                    <div className="help-overlay-contact-bottom">
                      <div
                        className="additional-info"
                        dangerouslySetInnerHTML={{
                          __html: box.additionalInformation,
                        }}
                      />
                    </div>
                  </InfoCardWrapper>
                )
              );
            case HelpOverlayBoxes.BoxGeneral:
              return (
                <InfoCardWrapper
                  icon={box.icon}
                  boxesCount={boxes.length}
                  link={box.targetUrl}
                  key={i}
                  onClick={() => {
                    trackClicks({
                      type: ClickEventType.BOX,
                      payload: { source: 'help' },
                    });
                    onClose?.();
                  }}
                  className="info-card"
                  wrapperClassName={`overlay-box box-default ${
                    isB2b ? 'b2b' : 'b2c'
                  }`}
                >
                  <HelpOverlayTitle>
                    <h4>{box.mainTitle}</h4>
                  </HelpOverlayTitle>
                  <div className="help-overlay-content-icon">
                    <HelpOverlayContent>
                      {!mobile && box.mainText && (
                        <div
                          className="-image-text"
                          dangerouslySetInnerHTML={{ __html: box.mainText }}
                        />
                      )}
                    </HelpOverlayContent>
                    {box?.targetUrl && getRouterLink(box?.targetUrl) ? (
                      <HelpOverlayIcon className="help-overlay-icon">
                        <div className="-icon-link">
                          <div className="-icon-border">
                            <Icon
                              name="chevron-right"
                              className="-icon-chevron-right"
                            />
                          </div>
                        </div>
                      </HelpOverlayIcon>
                    ) : null}
                  </div>
                </InfoCardWrapper>
              );
            default:
              return null;
          }
        })
        .filter(Boolean)}
      <HelpOverlayClose onClick={closeHelpOverlay}>
        <Icon name="close" />
        {t('common:close')}
      </HelpOverlayClose>
    </HelpOverlayWrapper>
  );
};
