import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { HelpOverlayBoxesFragmentDoc } from './HelpOverlayFragments.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetHelpOverlayQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetHelpOverlayQuery = {
  __typename?: 'Query';
  helpOverlayData: {
    __typename?: 'HelpOverlayData';
    helpOverlayBoxes?: Array<
      | {
          __typename?: 'HelpOverlayBox';
          mainText?: string | null;
          mainTitle?: string | null;
          icon: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              id: string;
              url: string;
            }>;
          };
          targetUrl?:
            | {
                __typename?: 'ExternalLink';
                openMethod: Types.OpenLinkMethod;
                url: string;
                caption?: string | null;
              }
            | {
                __typename?: 'InternalLink';
                caption?: string | null;
                uri: string;
                targetPageType: Types.PageType;
              }
            | null;
        }
      | {
          __typename?: 'HelpOverlayBoxChat';
          mainText?: string | null;
          mainTitle?: string | null;
          icon: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              id: string;
              url: string;
            }>;
          };
          targetUrl?:
            | {
                __typename?: 'ExternalLink';
                openMethod: Types.OpenLinkMethod;
                url: string;
                caption?: string | null;
              }
            | {
                __typename?: 'InternalLink';
                caption?: string | null;
                uri: string;
                targetPageType: Types.PageType;
              }
            | null;
        }
      | {
          __typename?: 'HelpOverlayBoxContact';
          mainText?: string | null;
          mainTitle?: string | null;
          additionalInformation?: string | null;
          icon: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              id: string;
              url: string;
            }>;
          };
          targetUrl?:
            | {
                __typename?: 'ExternalLink';
                openMethod: Types.OpenLinkMethod;
                url: string;
                caption?: string | null;
              }
            | {
                __typename?: 'InternalLink';
                caption?: string | null;
                uri: string;
                targetPageType: Types.PageType;
              }
            | null;
        }
    > | null;
  };
};

export const GetHelpOverlayDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHelpOverlay' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpOverlayData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'helpOverlayBoxes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'helpOverlayBoxes' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...HelpOverlayBoxesFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetHelpOverlayQuery__
 *
 * To run a query within a React component, call `useGetHelpOverlayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHelpOverlayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHelpOverlayQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetHelpOverlayQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetHelpOverlayQuery,
    GetHelpOverlayQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetHelpOverlayQuery,
    GetHelpOverlayQueryVariables
  >(GetHelpOverlayDocument, options);
}
export function useGetHelpOverlayLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHelpOverlayQuery,
    GetHelpOverlayQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetHelpOverlayQuery,
    GetHelpOverlayQueryVariables
  >(GetHelpOverlayDocument, options);
}
export type GetHelpOverlayQueryHookResult = ReturnType<
  typeof useGetHelpOverlayQuery
>;
export type GetHelpOverlayLazyQueryHookResult = ReturnType<
  typeof useGetHelpOverlayLazyQuery
>;
export type GetHelpOverlayQueryResult = Apollo.QueryResult<
  GetHelpOverlayQuery,
  GetHelpOverlayQueryVariables
>;
