import {
  IInactivityTimerProps,
  useInactivityTimer,
} from 'components/domain/timer/useInactivityTimer';

const OVERLAY_OPEN_COUNT_SESSION_STORAGE_NAME = 'overlayOpenedTimes';
const OVERLAY_OPEN_COUNT_LIMIT = 2;

export function useHelpOverlayInactivityTimer(props: IInactivityTimerProps) {
  useInactivityTimer({
    ...props,
    onIdle: () => {
      const overlayOpenedTimes = parseInt(
        sessionStorage.getItem(OVERLAY_OPEN_COUNT_SESSION_STORAGE_NAME) || '0',
        10
      );
      if (overlayOpenedTimes >= OVERLAY_OPEN_COUNT_LIMIT) return;
      props.onIdle();
      sessionStorage.setItem(
        'overlayOpenedTimes',
        (overlayOpenedTimes + 1).toString()
      );
    },
  });
}
