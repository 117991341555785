import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { LinkFragmentFragmentDoc } from 'graphql/link/Link.generated';
export type HelpOverlayBoxFragment = {
  __typename?: 'HelpOverlayBox';
  mainText?: string | null;
  mainTitle?: string | null;
  icon: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; id: string; url: string }>;
  };
  targetUrl?:
    | {
        __typename?: 'ExternalLink';
        openMethod: Types.OpenLinkMethod;
        url: string;
        caption?: string | null;
      }
    | {
        __typename?: 'InternalLink';
        caption?: string | null;
        uri: string;
        targetPageType: Types.PageType;
      }
    | null;
};

export type HelpOverlayBoxChatFragment = {
  __typename?: 'HelpOverlayBoxChat';
  mainText?: string | null;
  mainTitle?: string | null;
  icon: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; id: string; url: string }>;
  };
  targetUrl?:
    | {
        __typename?: 'ExternalLink';
        openMethod: Types.OpenLinkMethod;
        url: string;
        caption?: string | null;
      }
    | {
        __typename?: 'InternalLink';
        caption?: string | null;
        uri: string;
        targetPageType: Types.PageType;
      }
    | null;
};

export type HelpOverlayBoxContactFragment = {
  __typename?: 'HelpOverlayBoxContact';
  mainText?: string | null;
  mainTitle?: string | null;
  additionalInformation?: string | null;
  icon: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; id: string; url: string }>;
  };
  targetUrl?:
    | {
        __typename?: 'ExternalLink';
        openMethod: Types.OpenLinkMethod;
        url: string;
        caption?: string | null;
      }
    | {
        __typename?: 'InternalLink';
        caption?: string | null;
        uri: string;
        targetPageType: Types.PageType;
      }
    | null;
};

export type HelpOverlayBoxesHelpOverlayBoxFragment = {
  __typename?: 'HelpOverlayBox';
  mainText?: string | null;
  mainTitle?: string | null;
  icon: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; id: string; url: string }>;
  };
  targetUrl?:
    | {
        __typename?: 'ExternalLink';
        openMethod: Types.OpenLinkMethod;
        url: string;
        caption?: string | null;
      }
    | {
        __typename?: 'InternalLink';
        caption?: string | null;
        uri: string;
        targetPageType: Types.PageType;
      }
    | null;
};

export type HelpOverlayBoxesHelpOverlayBoxChatFragment = {
  __typename?: 'HelpOverlayBoxChat';
  mainText?: string | null;
  mainTitle?: string | null;
  icon: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; id: string; url: string }>;
  };
  targetUrl?:
    | {
        __typename?: 'ExternalLink';
        openMethod: Types.OpenLinkMethod;
        url: string;
        caption?: string | null;
      }
    | {
        __typename?: 'InternalLink';
        caption?: string | null;
        uri: string;
        targetPageType: Types.PageType;
      }
    | null;
};

export type HelpOverlayBoxesHelpOverlayBoxContactFragment = {
  __typename?: 'HelpOverlayBoxContact';
  mainText?: string | null;
  mainTitle?: string | null;
  additionalInformation?: string | null;
  icon: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; id: string; url: string }>;
  };
  targetUrl?:
    | {
        __typename?: 'ExternalLink';
        openMethod: Types.OpenLinkMethod;
        url: string;
        caption?: string | null;
      }
    | {
        __typename?: 'InternalLink';
        caption?: string | null;
        uri: string;
        targetPageType: Types.PageType;
      }
    | null;
};

export type HelpOverlayBoxesFragment =
  | HelpOverlayBoxesHelpOverlayBoxFragment
  | HelpOverlayBoxesHelpOverlayBoxChatFragment
  | HelpOverlayBoxesHelpOverlayBoxContactFragment;

export const HelpOverlayBoxFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'helpOverlayBox' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HelpOverlayBox' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'StringValue', value: '1', block: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resized' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'linkFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...LinkFragmentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const HelpOverlayBoxChatFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'helpOverlayBoxChat' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HelpOverlayBoxChat' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'StringValue', value: '1', block: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resized' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'linkFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...LinkFragmentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const HelpOverlayBoxContactFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'helpOverlayBoxContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HelpOverlayBoxContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'additionalInformation' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'StringValue', value: '1', block: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resized' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'linkFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...LinkFragmentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const HelpOverlayBoxesFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'helpOverlayBoxes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HelpOverlayBoxBase' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'helpOverlayBox' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'helpOverlayBoxChat' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'helpOverlayBoxContact' },
          },
        ],
      },
    },
    ...HelpOverlayBoxFragmentDoc.definitions,
    ...HelpOverlayBoxChatFragmentDoc.definitions,
    ...HelpOverlayBoxContactFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
